import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from '../templates/blog-post';
import { graphql } from 'gatsby';
import CodeExample from '../components/CodeExample';
import Code from '../components/code';
import Caption from '../components/Caption';
import LittleImg from '../components/LittleImg';
export const _frontmatter = {
  "title": "The Power of Mastery",
  "date": "2020-04-27",
  "promo": "grids",
  "description": "The four rewards of leveling up",
  "color": "#b24fd6"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = BlogPost;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    {
      /* 
       - promote GC
       - PAIN: being mediocre sucks, is boring and is limiting
       - SOLUTION: choose the path of mastery
       - create a desire in the user to get really good at their craft
       - why mastery is awesome
       - examples of mastery
       - how to gain mastery (later)
       - how mastery games promotes mastery
       - promote GC
        LINKS:
       - mastery-games-platform
      */
    }
    {
      /* PERKS */
    }
    <p>{`A long time ago before I knew how to code, I worked at a call center. Every day I looked forward to my lunch break. I'd clock out, hop on my little Vespa scooter, and zoom home to my apartment. I'd wolf down a quick sandwich and log in to World of Warcraft. `}</p>
    <p>{`50 minutes left — had to make it count. Accept new quest. Equip new bow. Ready to take on the world, my pet bear Chewy and I set out to kill those 10 rats that had been tormenting the poor blacksmith.`}</p>
    <p>{`After making short work of the vermin, we returned to town to claim our reward. Gratitude, 20 coins and some decent new pants — not too shabby. But then the real reward came. A loud ding sound, a flash of light, and a dozen "grats" messages from the local chat. `}</p>
    <p>{`I had leveled up.`}</p>
    <LittleImg width="500px" src="/img/wowhunter.jpg" mdxType="LittleImg" />
    <Caption mdxType="Caption">illustration by <a href="https://www.deviantart.com/gfxpixel">gfxpixel</a></Caption>
    <p>{`I zipped back to work with 6 minutes left and a smile on my face.`}</p>
    <h2>{`Transformation`}</h2>
    <p>{`Those clever psychologists at Blizzard knew how to make addicting games by mimicking the real-world reward systems. When you level up in Warcraft you experience a huge boost of endorphins. Your character's base stats are all increased: more life, more damage, more mana. You can carry better weapons and equip stronger armor. You're ready to take on even bigger challenges.`}</p>
    <p>{`There is nothing quite like that feeling of progress. `}</p>
    <p>{`It takes more time and effort in real life, but when you put in the work towards mastering a new skill, you are transformed. Your brain's synapses are literally rewired for the accomplishment of that task in the future. The experience you gain `}<em parentName="p">{`changes`}</em>{` you— for the better.`}</p>
    <p>{`I used to struggle with CSS. I'd have to interrupt my work and look things up all the time. I got really tired of that crap and finally tackled the beast. It took the reading (and re-reading) of specs, studying blog posts/examples, and most of all `}<strong parentName="p">{`playing`}</strong>{` with the tech— building a `}<em parentName="p">{`ton`}</em>{` of little projects myself. And I gradually leveled up. Now I feel so comfortable with CSS I don't even think about it while I work. I'm able to focus on the thing I'm building rather than the tools I use, and it feels incredible. `}</p>
    <p><img parentName="p" {...{
        "src": "/img/gc-chat.jpg",
        "alt": "CSS grid game"
      }}></img></p>
    <Caption mdxType="Caption">using CSS Grid code to save the critters in <a href="https://gridcritters.com">Grid Critters</a></Caption>
    <p>{`I've designed my games (`}<a parentName="p" {...{
        "href": "https://flexboxzombies.com"
      }}>{`Flexbox Zombies`}</a>{` and `}<a parentName="p" {...{
        "href": "https://gridcritters.com"
      }}>{`Grid Critters`}</a>{`) to help others attain this level of CSS mastery in the most fun way possible.`}</p>
    <p>{`On the other hand, I currently feel like a n00b at the new thing I'm learning: TypeScript. I hit the limits of my skills with it daily. Feeling like a beginner again — getting stuck and frustrated — is a good sign! It means you're learning. The trick is to use every single one of these moments as an opportunity to `}<strong parentName="p">{`grow`}</strong>{`. Don't just google the answer and paste it in, that won't `}<em parentName="p">{`change`}</em>{` you one bit. Study the thing that stumped you. Take the time to understand `}<em parentName="p">{`why`}</em>{` the solution works. Sure, getting unstuck is your goal. But try to transform yourself into the person who gets `}<em parentName="p">{`yourself`}</em>{` unstuck. Over time you'll hit fewer and fewer snags as your mastery over the skill develops.`}</p>
    <h2>{`Opportunities`}</h2>
    <p>{`Just like in video games, this leveling up brings with it many new possibilities. For starters you can learn additional things that would have previously been over your head. You become able to build more advanced, complex and delightful creations. Your ability to appreciate and learn from the fine work of others is refined. Your creativity centers have more raw materials to work with, expanding your imagination and ability to generate original ideas. And of course, you can earn more money because you are more skilled at your craft. But as a wise man once said `}<em parentName="p">{`"Don't do anything just for the money"`}</em>{`.`}</p>
    <h2>{`Motivation`}</h2>
    <p>{`Nothing generates motivation like the pursuit (and use) of mastery. It's incredibly rewarding to witness your own transformation as you put in the effort. No dings sound, no light rays beam all around you, but you can feel yourself leveling up. Your inner drive getting replenished. Making progress on the road to mastery adds logs to the flame of your `}<a parentName="p" {...{
        "href": "https://www.stevepavlina.com/blog/2005/03/cultivating-burning-desire/"
      }}>{`burning desire`}</a>{`.`}</p>
    <p>{`People often think it goes the other way around. `}<em parentName="p">{`"If only I had the motivation, I could get really good at coding!"`}</em>{`. But then they wonder why they can't seem to stick to a plan long enough to get really good. Don't wait around for motivation to strike (it won't). Generate it yourself by setting out on the path of mastery.`}</p>
    <p>{`In Dan Pink's excellent book `}<a parentName="p" {...{
        "href": "https://www.audible.com/pd/Drive-Audiobook/B002ZF14MG"
      }}>{`Drive`}</a>{` he lists the three key ingredients of genuine motivation: autonomy, mastery, and purpose.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Mastery is a mindset: It requires the capacity to see your abilities not as finite, but as infinitely improvable. Mastery is a pain: It demands effort, grit, and deliberate practice.`}<br parentName="p"></br>{`
`}{`— Dan Pink`}</p>
    </blockquote>
    <p>{`You're never going to feel motivated on a project where you're not getting good at something that matters to you. Motivation only flows naturally when:`}</p>
    <ul>
      <li parentName="ul">{`you're working on a product you believe in (purpose)`}</li>
      <li parentName="ul">{`using tech you want to get good at (mastery)`}</li>
      <li parentName="ul">{`with a team/management that enables you to spread your wings (autonomy)`}</li>
    </ul>
    <p>{`Mastery is a key ingredient of motivation, and the one over which you have the most control.`}</p>
    <h2>{`Happiness`}</h2>
    <blockquote>
      <p parentName="blockquote">{`The happiness of a man in this life does not consist in the absence but in the mastery of his passions.`}<br parentName="p"></br>{`
`}{`— Alfred Lord Tennyson`}</p>
    </blockquote>
    <p>{`At first it takes hard work, pure and simple. But as you progress you begin to enjoy the skill you've worked hard to master. As your motivation shifts from extrinsic to intrinsic, the work itself becomes a reward.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/learn-to-draw.jpg",
        "alt": "learning to draw"
      }}></img></p>
    <p>{`When you're first learning to draw, it takes steady discipline and constant practice. But eventually drawing becomes a resource for you, something you do for the simple enjoyment of it. I'll write more about this `}<em parentName="p">{`intrinsic`}</em>{` motivation in a future post.`}</p>
    <p>{`One of my favorite audiobooks of all time is `}<a parentName="p" {...{
        "href": "https://www.audible.com/pd/Flow-Audiobook/B00QU99KN8"
      }}>{`Flow`}</a>{` by Mihaly Csikszentmihalyi. His life's study was to observe and figure out what makes people happy. He concluded that it isn't their leisure time, but rather their time spent in the state of flow that gives people the most joy and fulfillment.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Contrary to what we usually believe, moments like these, the best moments in our lives, are not the passive, receptive, relaxing times—although such experiences can also be enjoyable, if we have worked hard to attain them. The best moments usually occur when a person's body or mind is stretched to its limits in a voluntary effort to accomplish something difficult or worthwhile. Optimal experience is thus something that we make happen.`}</p>
    </blockquote>
    <p>{`Csikszentmihalyi discovered that happiness comes from the process of intentionally focusing everything you've got on one thing at a time. On narrowing in and deliberately progressing along the path of mastery.`}</p>
    <h2>{`Time to Level Up`}</h2>
    <p>{`Are you ready to commit yourself to the path of mastery? To finally become amazing at the skills your heart is drawn to? Transformation, opportunities, motivation and happiness will be your loot if you're willing to put in the work.`}</p>
    <p>{`Pick up `}<a parentName="p" {...{
        "href": "https://gridcritters.com?ref=power-of-mastery"
      }}>{`Grid Critters`}</a>{` and I'll guide you to master CSS Grid — the best thing to ever happen to CSS and the single most important skill a modern web dev needs to master.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      